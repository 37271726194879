.wrap {
  display: flex;
  position: relative;
  z-index: 99;
}
.box {
  cursor: pointer;
  height: 50px;
  width: 50px;
  border: 0.5px solid #ffffff;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px;
  background: rgba(255, 255, 255, 0.3);
}
.box:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}
.box:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 0.5px solid #ffffff;
}

.disabled {
  background: rgb(128, 128, 128, 0.8);
  cursor: not-allowed;
}

.btn_background {
  background: rgb(128, 128, 128, 0.8);
}

@media only screen and (max-width: 1020px) {
  .box {
    height: 36px;
    width: 36px;
  }
  .icon {
    height: 20px;
    width: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .box {
    height: 36px;
    width: 36px;
  }
  .icon {
    height: 20px;
    width: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .box {
    height: 30px;
    width: 30px;
  }
  .icon {
    height: 18px;
    width: 18px;
  }
  .box:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .box:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}