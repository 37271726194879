.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-top: 110px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerButton {
  min-width: 168px;
  height: 48px;
  padding: 0 15px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  user-select: none;
  margin-right: 16px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.inner {
  width: 100%;
  max-width: 1280px;
}

.topContainer {
  display: flex;
  flex-direction: row;
  padding: 0 40px;
}

.itemSummary {
  flex: 1 1 0;
  max-width: 500px;
}

.itemMain {
  flex: 1 1 0;
  margin-left: 40px;
  overflow: hidden;
}

.itemMedia {
  margin: 40px 0;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #bbb;

  .media {
    position: relative;
    padding-top: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border: none;
    }
  }
}

.previewList {
  overflow-x: scroll;
  display: flex;
  align-items: center;
}

.preview {
  flex: 0 0 80px;
  height: 80px;
  margin: 5px 8px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
  }

  &.active {
    border-color: #007bff;

    img {
      border-radius: 0px;
    }
  }
}

.property {
  display: flex;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.propertyLabel {
  font-weight: 700;
  margin-right: 12px;
  white-space: nowrap;

  &::first-letter {
    text-transform: capitalize;
  }
}

.collectionDescription {
  font-size: 14px;
}

.socialLinks {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.socialLink {
  width: 20px;
  height: 20px;
  padding: 4px;
  margin-right: 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.panelTitle {
  display: flex;
  align-items: center;
}

.panelValue {
  color: black;
}
a.panelValue {
  text-decoration: underline;
}

.propertyValue {
  a {
    color: black;
    text-decoration: underline;
  }
}

.itemInfo {
  display: none;
  position: relative;
  overflow: hidden;
}

.itemInfoCont {
  margin: 40px 0;
  border-radius: 10px;
  border: 1px solid #eaeaf1;
  overflow: hidden;
}

.itemCategory {
  font-size: 16px;
  font-weight: 400;
  background: -webkit-linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemName {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #3d3d3d;
  margin-top: 16px;
  margin-right: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemDescription {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.itemStats {
  margin-top: 34px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #a2a2ad;
}

.itemOwner {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #a2a2ad;
}

.ownerAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ownerName,
.owner a,
.from a,
.to a {
  text-decoration: none;
  background: -webkit-linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.itemViews {
  margin-left: 20px;
  display: flex;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }

  &.clickable {
    cursor: pointer;
  }
}

.liking {
  color: #d905a6 !important;
}

.favIcon {
  width: 16px !important;
  &:hover {
    color: #007bff;
  }
}

.panelBody {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
}

.panelLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.listings,
.offers,
.items {
  margin-bottom: -1px;
}

.listing,
.offer,
.bundleItem {
  padding-left: 21px;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #eaeaf1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #121223;
}

.items {
  max-height: 400px;
  overflow-y: auto;
}

.bundleItem {
  height: 80px;
  text-decoration: none;
}

.bundleItemImage {
  flex: 0 0 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.bundleItemInfo {
  margin: 0 14px;
  overflow: hidden;
}

.bundleItemCategory {
  font-size: 18px;
  color: #007bff;
}

.bundleItemCategory,
.bundleItemName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bundleItemSupply {
  margin-left: auto;
  margin-right: 14px;
  font-size: 18px;
}

.noOffers {
  padding-top: 40px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noOffersLabel {
  font-weight: 400;
  font-size: 14px;
  color: #a2a2ad;
}

.makeOffer {
  margin-top: 27px;
  width: 168px;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}

.heading {
  background-color: #f6f7f9;
}

.owner {
  flex: 2;
}

.owner a,
.from a,
.to a {
  width: fit-content;
  display: flex;
  align-items: center;
}

.userAvatarWrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 6px;
}

.userAvatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.price {
  flex: 2;
  display: flex;
  align-items: center;
}

.subTitle {
  flex: 2;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.quantity {
  flex: 2;
}

.deadline {
  flex: 3;
}

.buy {
  flex-basis: 100px;
  padding: 5px 4px;
}

.tokenIcon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
}

.buyButton {
  min-width: 80px;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #fff;
  background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }
}

.bids {
  padding: 20px 16px;
}

.result {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.bidtitle {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.bidAmount {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.placeBid,
.withdrawBid {
  min-width: 120px;
  width: fit-content;
  height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  user-select: none;
  margin-top: 15px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.placeBid {
  background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  color: #fff;
}

.withdrawBid {
  background-color: #fff;
  color: #007bff;
  border: 1px solid #007bff;
}

.tradeHistoryWrapper {
  margin: 0 40px 40px;
}

.tradeHistoryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tradeHistoryTitle {
  font-weight: 700;
  font-size: 16px;
  color: #121223;
}

.filter {
  cursor: pointer;
}

.filterIcon {
  width: 20px;
  height: 20px;
}

.histories {
  margin-top: 40px;
  max-height: 458px;
  overflow-x: auto;
  overflow-y: auto;
}

.history {
  min-width: 600px;
  height: 58px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 400;
  color: #121223;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &.heading {
    height: 38px;
    border-bottom: none;

    .from,
    .to {
      color: inherit;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.from,
.to {
  flex: 3;
}

.historyPrice {
  display: flex;
  align-items: center;
  flex: 2;
}

.saleDate {
  flex: 2;
}

.loadingIndicator {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemsList {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  margin-bottom: -20px;
}

.moreItem {
  width: 240px;
  flex: 0 0 240px;
  margin-right: 20px;
}

.itemInfoWrapper {
  margin: 40px 0;
  position: relative;
}

.itemMenu {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  border: 1px solid #eaeaf1;
  border-radius: 6px;
}

.itemMenuBtn {
  width: 48px;
  height: 48px;
  border-right: 1px solid #eaeaf1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:last-child {
    border-right: 0;
  }
}

.itemMenuIcon {
  width: 17px;
  height: 17px;
  color: #a2a2ad;
}

.shareMenu {
  transform: translateY(55px) !important;
}

.shareMenuList {
  padding: 0 !important;
}

.menuItem {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #121223;

  img {
    width: 20px;
    margin-right: 16px;
  }
}

.infoPanel {
  display: none;
}

.panelWrapper {
  min-width: 550px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #eaeaf1;
  box-sizing: border-box;
  overflow: hidden;
}

.unlockableLabel {
  font-weight: 500;
  font-size: 18px;
  color: #121223;
}

.unlockableContent {
  width: 100%;
  height: 100px;
  resize: none;
  margin: 20px 0 0;
  outline: none;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
}

.bestBuy {
  padding: 14px 20px 20px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #eaeaf1;
  box-sizing: border-box;
}

.currentPriceLabel {
  font-size: 18px;
  font-weight: 700;
  color: #a2a2ad;
}

.currentPriceWrapper {
  margin-top: 18px;
  display: flex;
  align-items: center;
}

.tokenLogo {
  width: 24px;
  height: 24px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.currentPrice {
  font-weight: 700;
  font-size: 28px;
  color: #121223;
  margin-right: 8px;
}

.currentPriceUSD {
  font-weight: 400;
  font-size: 14px;
  color: #a2a2ad;
}

.revealBtn,
.buyNow {
  margin-top: 20px;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chartWrapper {
  padding: 20px;
  height: 250px;
  overflow: hidden;
  position: relative;
}

.chart {
  position: absolute;
  overflow: hidden;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

.tooltip {
  font-size: 14px;
}

.hidden {
  display: none;
}

.filtermenu {
  width: 220px;
  padding: 24px;
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
}

.menuList {
  padding: 0 !important;
}

.menuTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #3d3d3d;
}

.menu {
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

  img {
    width: 16px;
    margin-left: 32px;
    display: none;
  }

  &.active {
    color: rgba(0, 0, 0, 0.6);

    img {
      display: block;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .topContainer {
    flex-direction: column;
  }

  .itemSummary {
    max-width: initial;
    display: flex;
    align-items: flex-start;
  }

  .itemMedia {
    width: 280px;
  }

  .itemMain {
    margin-left: 0;
  }

  .itemInfo {
    flex: 1;
    display: block;
    margin: 40px 0 40px 40px;
    width: 100%;
  }

  .itemInfoCont {
    display: none;
  }

  .itemInfoWrapper {
    display: none;
  }

  .infoPanel {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .itemStats {
    flex-wrap: wrap;
  }

  .itemOwner {
    flex-basis: 100%;
  }

  .itemViews {
    margin-left: 0;
    margin-top: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .itemSummary {
    flex-direction: column;
  }

  .itemMedia {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .itemInfo {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 155px;
  }

  .header {
    position: relative;
    top: inherit;
    flex-direction: column;
    padding-right: 0;
  }

  .headerButton {
    margin-right: 0;
    margin-bottom: 16px;
    height: 48px;
    font-size: 16px;
    width: calc(100% - 32px);
    max-width: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .topContainer {
    padding: 0 30px;
  }

  .tradeHistoryWrapper {
    margin: 0 30px 30px;
  }

  .itemStats {
    flex-direction: column;
    align-items: flex-start;
  }

  .itemOwner {
    flex-flow: wrap;
  }

  .itemOwner .itemViews {
    flex-basis: 100%;
    break-after: always;

    &:first-child {
      margin-top: 0;
    }
  }

  .itemViews {
    margin-left: 0;
    margin-top: 20px;
  }

  .panelWrapper {
    width: 100%;
    min-width: inherit;
  }

  .listings,
  .offers {
    min-width: 550px;
  }

  .moreItem {
    width: calc(100vw - 130px);
    flex-basis: calc(100vw - 130px);
  }
}
