.container {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  padding-top: 80px;
  box-sizing: border-box;
}

.body {
  width: 1200px;
  max-width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
}

.main {
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 6rem;
}

.title {
  font-weight: 700;
  font-size: 250px;
  line-height: 204px;
  letter-spacing: 10px;
  color: #121223;
}

.ooops {
  font-weight: 700;
  font-size: 40px;
  line-height: 42px;
  letter-spacing: 2px;
  color: #000;
  text-align: right;
  margin: 12px 0 15px;
}
.subtitle {
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #a2a2ad;
  text-align: right;
}

.button {
  margin-top: 36px;
  width: 188px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.228571px;
  box-shadow: none;
  border-radius: 11px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  background: linear-gradient(90deg, #D905A6 0%, #FCBE0B 100%);
  color: #fff;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

.subContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%;
  // background-color: blue;
}

.man {
  height: 30rem;
  width: 40%;
}

@media only screen and (max-width: 1100px) {
  .main {
    align-items: center;
  }

  .title {
    font-size: 160px;
    line-height: 170px;
  }

  .subtitle {
    font-size: 22px;
    line-height: 35px;
    margin-top: 8px;
  }
}

@media only screen and (max-width: 810px) {
  .body {
    flex-direction: column-reverse;
    margin-top: 50px;
  }

  .main {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
  }
  .ooops {
    margin: 0px;
  }

  .title {
    font-size: 144px;
  }

  .subtitle {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .button {
    width: 168px;
    height: 48px;
    font-size: 16px;
  }

  .man {
    width: 60%;
    min-width: 300px;
  }
}
@media only screen and (max-width: 600px) {
  .body {
    flex-direction: column-reverse;
    margin-top: 50px;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .title {
    font-size: 130px;
  }

  .subtitle {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .button {
    width: 168px;
    height: 48px;
    font-size: 16px;
  }

  .man {
    width: 60%;
    min-width: 300px;
    height: 18rem;
  }
  .main {
    padding-bottom: 1.5rem;
  }
}
