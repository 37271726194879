.footerWrapper {
  position: relative;
  width: 100%;
  left: 0;
  padding: 1% 0;
  box-sizing: border-box;
  background: linear-gradient(
    90deg,
    rgba(217, 5, 166, 0.4) 0%,
    rgba(252, 190, 11, 0.4) 100%
  );
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.contentTop {
  width: 80%;
  display: flex;
  padding-bottom: 48px;
  justify-content: space-between;
}

.listWrapper {
  margin-top: 33px;
  display: flex;
  align-items: flex-start;
}

.listItem {
  margin: 0;
  padding: 0;
  margin-bottom: 26px;
  font-size: 22px;
  line-height: 140%;
  cursor: pointer;
}

.list {
  margin-right: 76px;

  &:last-child {
    margin-right: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 42px;
    margin-bottom: 26px;
    margin-top: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    a, span {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.mediaIcons {
  display: flex;
  align-items: center;
  width: 100%;
}

.mediaItem {
  margin-right: 15px;
}

.logoWrapper {
  width: 121px;
  height: 121px;
  margin-right: 30px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.contentBottom {
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 20px;

  span {
    margin: 0 6px;
    cursor: pointer;

    a {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

@media only screen and (max-width: 1600px) {
  .contentBottom {
    font-size: 16px;
  }

  .listItem {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .footerWrapper {
    padding: 0px 20px 20px 20px;
  }
  .listWrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
  .contentTop {
    width: 80%;
    padding-bottom: 28px;
  }
  .list {
    margin-right: 0px;
    margin-bottom: 26px;
  }
  .logoWrapper {
    margin-bottom: -31px;
    margin-left: -10px;
    margin-top: -20px;
    width: 100px;
  }
}


@media only screen and (max-width: 700px) {
  .footerWrapper {
    padding: 0px 20px 20px 20px;
  }
  .listWrapper {
    flex-direction: column;
  }
  .contentTop {
    width: 80%;
    padding-bottom: 28px;
    flex-direction: column;
  }
  .list {
    margin-right: 0px;
    margin-bottom: 26px;
  
    &:last-child {
      margin-right: 0;
    }
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 16px;
      margin-top: 0;
    }
  }
  .logoWrapper {
    margin-bottom: -31px;
    margin-left: -10px;
    margin-top: -20px;
  }
}
