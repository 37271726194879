.wrapper {
  width: 220px;
  background: rgba(255, 255, 255, 0.3);
  margin-top: 12px;
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25),
    0px 4px 21px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  opacity: 1;
  transition: 0.2s;
}

.inactive {
  position: relative;
  z-index: -1;
  opacity: 0;
  transform: translateY(-10px);
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listItem {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 125%;
  color: #ffffff;
  cursor: pointer;
  background: rgb(128, 128, 128, 0.8);
  border-radius: 10px;
  &:hover {
    background: rgba(128, 128, 128, 0.1);
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
