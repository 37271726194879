.button {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 320px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  background-color: #1969ff;
  color: #fff;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.link {
  color: #1969ff;
  cursor: pointer;
  padding: 0 5px;
}

.container {
  width: 100%;
  box-sizing: border-box;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 24px;
  box-sizing: border-box;
  padding-bottom: 32px;
  padding: 4px;
}

.nft {
  margin: 12px;
}

.num1 {
  width: calc(100% - 24px);
  flex-basis: calc(100% - 24px);
}

.num2 {
  width: calc(50% - 24px);
  flex-basis: calc(50% - 24px);
}

.num3 {
  width: calc(33.33% - 24px);
  flex-basis: calc(33.33% - 24px);
}

.num4 {
  width: calc(25% - 24px);
  flex-basis: calc(25% - 24px);
}
@media only screen and (max-width: 768px) {
  .num4 {
    width: calc(50% - 24px);
    flex-basis: calc(50% - 24px);
  }
}
@media only screen and (max-width: 450px) {
  .num4 {
    width: calc(100% - 24px);
  }
}

.num5 {
  width: calc(20% - 24px);
  flex-basis: calc(20% - 24px);
}

.num6 {
  width: calc(16.66% - 24px);
  flex-basis: calc(16.66% - 24px);
}

.num7 {
  width: calc(14.28% - 24px);
  flex-basis: calc(14.28% - 24px);
}

.num8 {
  width: calc(12.5% - 24px);
  flex-basis: calc(12.5% - 24px);
}

.num9 {
  width: calc(11.11% - 24px);
  flex-basis: calc(11.11% - 24px);
}

.num10 {
  width: calc(10% - 24px);
  flex-basis: calc(10% - 24px);
}

.num11 {
  width: calc(9.09% - 24px);
  flex-basis: calc(9.09% - 24px);
}

.num12,
.num13,
.num14,
.num15,
.num16,
.num17,
.num18,
.num19,
.num20,
.num21,
.num22,
.num23,
.num24,
.num25,
.num26,
.num27,
.num28,
.num29,
.num30 {
  width: calc(8.33% - 24px);
  flex-basis: calc(8.33% - 24px);
}
