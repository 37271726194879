.container {
  margin-bottom: -1px;
  margin-top: 1px;
  border-bottom: 1px solid #eaeaf1;
  overflow: hidden;
}

.header {
  width: 100%;
  height: 60px;
  padding: 12px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.titleWrapper {
  display: flex;
  align-items: center;
}

.titleIcon {
  margin-right: 16px;
  width: 20px;
  height: 20px;
  color: #121223;
}

.title {
  font-size: 16px;
  font-weight: 700;
  color: #121223;
}

.icon {
  color: #a2a2ad;
}

.body {
  border-top: 1px solid #eaeaf1;
  max-height: 0;
  transition: max-height 100ms ease;

  &.open {
    max-height: 500px;
  }

  &.responsive.open {
    max-height: 800px;
  }

  &:not(.open) {
    border-top: none;
  }
}

@media only screen and (max-width: 600px) {
  .body {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
