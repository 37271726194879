.root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.newestListing {
  width: calc(24% - 16px);

  .ETHIcon {
    margin-top: 8px;
  }
}
@media only screen and (max-width: 768px) {
  .newestListing {
    width: calc(50% - 16px);
  }
}
@media only screen and (max-width: 425px) {
  .newestListing {
    width: calc(100% - 16px);
  }
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  min-height: 318px;
  cursor: pointer;
  transition: transform ease 0.1s;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fff;

  &:nth-child(n + 2) {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  &:nth-child(2) {
    width: 93%;
    top: 4px;
  }

  &:nth-child(3) {
    width: 96%;
    top: 1px;
  }

  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  }
}

.cardFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  height: 32px;
  color: rgba(0, 0, 0, 0.5);
  &.liking {
    color: #b51818;
  }
}

.favIcon {
  height: 13px !important;
  width: 14px !important;
  position: relative;
  top: -1px;
  right: 2px;
  &:hover {
    color: linear-gradient(120deg, #d800ab, #ffcc00);
  }
}

.footerRight {
  // color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  position: relative;
  top: 3px;
}

.favLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140.28%;
}

.createBtn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.createIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #1969ff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
  }
}

.createLabel {
  font-size: 16px;
  font-weight: 700;
  color: #1969ff;
  margin-top: 21px;
}

.link {
  height: 100%;
  text-decoration: inherit;
  display: flex;
  flex-direction: column;
}

.priceCol {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 4px;
  color: #a2a2ad;

  text-overflow: ellipsis;
  white-space: wrap;
  display: flex;
  align-items: center;
}

.verified {
  max-width: 12px;
  max-height: 12px;
  margin-top: 4px;
  margin-left: 4px;
}
.colName {
  display: flex;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 124.58%;
}

.checkIcon {
  font-size: 18px !important;
  color: #1969ff;
  margin-left: 4px;
}

.price {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 4px;

  img {
    height: 16px;
    margin-right: 6px;
  }
}
.buyNow {
  font-size: 16px;
  background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.label2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140.28%;
  margin-right: 4px;
  color: rgba(0, 0, 0, 0.5);

  .lastPrice {
    position: relative;
    top: 1px;
  }

  .price2 {
    font-weight: 400;
    font-size: 12px;
    color: #121223;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 4px;
  }
  img {
    height: 14px;
    max-width: 8px;
    margin-right: 4px;
  }
}

.name {
  flex: 1;
  margin: 0;
  color: #121223;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  min-height: 20px;
  line-height: 124.58%;
}

.name2 {
  flex: 1;
  font-weight: 700;
  font-size: 12px;
  margin: 0;
  color: #121223;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.timeLeft {
  position: absolute;
  width: 120px;
  right: -10px;
  bottom: 0;
}

.mediaBox {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.mediaPanel {
  width: 100%;
  height: 100%;
  display: flex;

  justify-self: center;
  align-self: center;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  height: 17vw;
}

.mediaLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel {
  width: 100%;
  height: 100%;
}

.imageBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 16em;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.08);
}

.media {
  height: 17vw;
  width: 17vw;
  max-height: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: 20px;
  padding: 8px;
  box-sizing: border-box;
}

.dots {
  position: absolute;
  bottom: 10px;
  left: 12px;
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #b0b0b0;
  background-color: #b0b0b0;
  box-sizing: border-box;
}

:global {
  .BrainhubCarousel__thumbnail {
    padding: 0 !important;
    opacity: 1 !important;
    margin-right: 5px;
  }

  .BrainhubCarousel__thumbnail--selected {
    :local {
      .dot {
        background-color: #007bff;
      }
    }
  }
}

.content {
  padding: 12px 8px !important;
  display: flex;
  flex-direction: column;
  min-height: 50px;
}

.topLine {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.itemName {
  margin-right: 16px;
  overflow: hidden;
  font-size: 10px !important;
}

.alignBottom {
  display: flex;
}

.alignRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
