.cardWrapper {
  width: calc(25% - 18px);
  height: 260px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
  }
}

@media only screen and (max-width: 800px) {
  .cardWrapper {
    width: 100%;
  }
  
}

.imageContainer {
  width: 100%;
  height: 130px;
  background-color: grey;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.textContainer {
  width: 100%;
  height: 130px;
}

.iconContainer {
  position: absolute;
  top: 16px;
  left: 16px;
}

.imagePlaceholder {
  width: 100%;
  height: 130px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 110%
    ),
    rgb(229, 232, 235);
}

.circleWrapper {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  left: calc(50% - 80px / 2 - 0.5px);
  top: 89px;
  border: 3px solid white;
  box-sizing: border-box;
  z-index: 99;
  overflow: hidden;
  background-color: lightgrey;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 12px;
  line-height: 125%;
  margin-top: 48px;
}

.verifiedIcon {
  margin-left: 5px;
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.subtitle {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 125%;
  font-size: 10px;

  span {
    &:first-child {
      color: rgba(0, 0, 0, 0.5);
    }
    &:last-child {
      background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-left: 4px;
    }
  }
}

.text {
  margin: 8px 16px 0 16px;
  font-size: 10px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mediaImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
