.cardWrapper {
  box-sizing: border-box;
  width: 100%;
  height: 840px;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  background-color: white;
  cursor: pointer;
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 370px;
  padding: 3%;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}
.textContentTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  height: 70%;
  overflow: hidden;
}

.link {
  text-decoration: none;
  color: inherit;
}

.owner {
  font-size: 22px;
  text-align: left;
}

.imageWrapper {
  flex: 1;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  margin: 0;
  text-align: left;
}

.itemName {
  font-weight: 600;
  font-size: 36px;
  overflow: hidden;
  line-height: 46px;
  text-overflow: ellipsis;
  color: rgb(58, 29, 29);
  margin-bottom: 18px;
  text-align: left;
}

.description {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  padding: 0;
  text-align: left;
  text-transform: capitalize;
  height: 30%;
  overflow: hidden;
}

@media only screen and (min-width: 2000px) {
  .textContent {
    width: 563px;
  }
}
@media only screen and (max-width: 1600px) {
  .cardWrapper {
    height: 530px;
  }
}
@media only screen and (max-width: 800px) {
  .textContent {
    width: 28%;
  }
  .title, .owner {
    font-size: 14px;
    line-height: 22px;
  }
  .description {
    font-size: 10px;
  }
}
