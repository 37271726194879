.wrapper {
  box-sizing: border-box;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(217, 5, 166, 0.2) 0%,
    rgba(252, 190, 11, 0.2) 100%
  );
  padding: 80px 12.5%;
}

h1 {
  font-weight: 600;
  font-size: 42px;
  line-height: 72px;
  margin: 0;
  margin-bottom: 37px;
}

.smallCardsWrapper {
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 23px;
}

.carouselWrapper {
  width: 99.9%;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  overflow: hidden;
}

@media only screen and (max-width: 1450px) {
  .wrapper {
    padding: 20px 12.5%;
  }
  .smallCardsWrapper {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 32px;
    margin-bottom: 14px;
  }
}
@media only screen and (max-width: 768px) {
  .smallCardsWrapper {
    flex-wrap: wrap;
  }
  .wrapper {
    padding: 30px 6.5%;
  }
  h1 {
    font-size: 34px;
    margin-bottom: 28px;
    line-height: 42px;
    width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 425px) {
  .carouselWrapper {
    display: none;
  }
  .smallCardsWrapper {
    flex-direction: column;
    margin-top: 0px;
    width: 80%;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }
  h1 {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
}
