.container {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  box-sizing: border-box;
}

.containerWrapper {
  margin-top: 80px;
  box-sizing: border-box;
}

.imageBanner {
  width: 100%;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  margin: 0 auto;
  margin-top: 40px;
  width: 80%;
  box-sizing: border-box;
  padding-bottom: 202px;
}

.title {
  font-weight: 600;
  font-size: 36px;
  line-height: 60px;
  margin: 0;
  span {
    margin-left: 29px;
    background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.sortPanel {
  margin-top: 22px;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  display: flex;
  gap: 36px;
  justify-content: center;
  overflow-y: hidden;

  .sortItem {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 4px 4px 0 4px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .active {
    background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    border-bottom: 2px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  }
}

.cardsWrapper {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px 24px;
  justify-content: flex-start;
}

@media only screen and (max-width: 1600px) {
  .content {
    width: 90%;
  }

  .sortPanel {
    gap: 24px;
  }
}

@media only screen and (max-width: 1420px) {
  .content {
    width: 80%;
    margin-top: 20px;
    padding-bottom: 50px;
  }
  .title {
    font-size: 30px;
  }
  .sortPanel {
    gap: 20px;
    margin-top: 14px;
    .sortItem {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .sortPanel {
    overflow-x: scroll;
    justify-content: flex-start;
    .sortItem {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .title {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    span {
      margin-left: 9px;
    }
  }
  .cardsWrapper {
    flex-direction: column;
  }
}

// Remove horizontal scroll for touch devices
@media (hover: none), (hover: on-demand), (-moz-touch-enabled: 1), (pointer:coarse) {
  .sortPanel {
    -ms-overflow-style: none; 
    scrollbar-width: none;
  }

  .sortPanel::-webkit-scrollbar {
    display: none;
  }
}