.container {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  padding-top: 80px;
  box-sizing: border-box;
}

.inner {
  width: 80%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  padding: 56px 0 60px 0;
  margin: 0 auto;
}

.title {
  font-size: 36px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 18px;
}

.content {
  display: flex;
  gap: 48px;
}

.uploadtitle,
.uploadsubtitle {
  font-size: 22px;
}

.columnLeft {
  max-width: 694px;
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.columnRight {
  flex: 1;
  max-width: 694px;
  height: 100%;
  position: relative;
}

.inputGroup {
  position: relative;
  margin: 24px 0;
}

.switcherContent {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 24px;
}

.inputTitle {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  display: flex;
  align-items: center;
  div {
    color: #e15a5a;
  }
}

.inputSubTitle {
  margin-top: 7px;
  font-weight: 400;
  font-size: 12px;
  color: #a2a2ad;
}

.inputWrapper {
  margin-top: 7px;
}

.option {
  margin: 0;
  width: 100%;
  height: 56px;
  background-color: #fafafb;
  margin-bottom: 16px;
  border-left: 4px solid #121223;
  box-sizing: border-box;
  padding-left: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    border-left-color: #1969ff;
  }
}

.optionLabel {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.01em;
  color: #121223;
  margin-left: 24px;
}

.lengthIndicator {
  font-size: 13px;
  font-weight: 400;
  margin-top: 6px;
  margin-right: 6px;
  text-align: right;
  color: #a2a2ad;

  & + .error {
    margin-top: -16px;
  }
}

.error {
  font-size: 13px;
  font-weight: 400;
  margin: 6px 0 0 6px;
  color: rgba(235, 87, 87);
}

.hasError {
  border-color: rgb(235, 87, 87) !important;
}

.logoUploadBox {
  box-sizing: border-box;
  position: relative;
  img {
    width: 300px;
    height: 300px;
    object-fit: contain;
    border: none;
  }
}

.bannerUploadBox {
  box-sizing: border-box;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: none;
  }
}

.removeLogoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  opacity: 0;
  transition: opacity ease 100ms;

  &:hover {
    opacity: 1;
  }
}

.removeOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  opacity: 0;
  transition: opacity ease 100ms;

  &:hover {
    opacity: 1;
  }
}

.removeIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
  }
}

.uploadOverlay {
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: opacity ease 0.1s;
  display: flex;
  height: 300px;
  width: 300px;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='53' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
}

.banner {
  height: 400px;
  width: 100%;
}

.uploadtitle {
  box-sizing: border-box;
  padding: 7px;
  position: relative;
  text-align: center;
  padding: 0 24px;
  font-size: 14px;
}

.uploadInner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ededf3;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60px;
    height: 60px;
  }
}

.plusIcon {
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  right: -11px;
  bottom: 17px;
  background-color: #1969ff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
    height: 12px;
  }
}

.input {
  width: 100%;
  height: 44px;
  padding: 10px 24px 8px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  border-radius: 10px;
  outline: none;
  border: 1px solid #eaeaf1;

  &:focus {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  }
}

.longInput {
  height: 133px;
  resize: vertical;
  margin-bottom: -8px;
}

.categoryList {
  display: flex;
  align-items: center;
}

.categoryButton {
  flex: 0 0 120px;
  height: 48px;
  padding: 8px 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #121223;
  letter-spacing: -0.02em;
  border: 1px solid #eaeaf1;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  }
}

.catImg {
  width: 24px;
}

.menu {
  min-width: 160px;
  max-height: 350px;
  overflow-y: auto;
}

.category {
  height: 48px;
}

.categoryIcon {
  width: 20px;
  height: 20px;
}

.categoryLabel {
  margin-left: 12px;
}

.selectedCategory {
  flex: 0 0 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #121223;
  line-height: 20px;
  box-sizing: border-box;
  border: 1px solid #eaeaf1;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  }
}

.closeIcon {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.linksWrapper {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.linkItem {
  display: flex;
  flex-direction: row;
  height: 50px;
  border: 1px solid #eaeaf1;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 27px 0px;

  &:first-child {
    margin: 0;
  }

  &:last-child {
    margin: 0;
  }
}

.linkIconWrapper {
  padding: 15px 9px 12px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkIcon {
  width: 28px;
  height: auto;
  color: #999;
}

.linkInput {
  flex-grow: 1;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
  background-color: transparent;
  flex-basis: 0px;

  &::placeholder {
    color: #888;
    font-weight: 300;
  }
}

.buttonsWrapper {
  margin-top: 40px;
  display: flex;
}

.createButton {
  width: 168px;
  height: 48px;
  border-radius: 8px;
  background-color: #1969ff !important;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

.fee {
  margin-top: 16px;
  display: flex;
  align-items: center;
  color: #3d3d3d;
  opacity: 0.6;
}

@media only screen and (max-width: 1600px) {
  .inner {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 155px;
  }
  .content {
    flex-direction: column;
    gap: 0px;
  }

  .inner {
    // margin: 0 auto;
    padding: 0 40px;
    width: 100%;
  }
  .columnRight {
    margin-bottom: 40px;
  }

  .categoryList {
    flex-direction: column;
    align-items: flex-start;
  }

  .categoryButton,
  .selectedCategory {
    flex: 0 0 48px;
  }

  .selectedCategory {
    margin-left: 0;
    margin-top: 16px;
  }

  .linkIconWrapper {
    flex: 0 0 50px;
  }

  .switcherContent {
    font-size: 15px;
    line-height: 23px;
  }
  .title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .inner {
    margin: 0;
    padding: 0 30px;
  }
  .banner {
    height: 340px;
  }

  .uploadOverlay {
    width: 100%;
  }
}
