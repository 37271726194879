.container {
  margin: 0 auto;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(217, 5, 166, 0.4) 0%,
    rgba(252, 190, 11, 0.4) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 137px;
}

.name {
  font-weight: 600;
  font-size: 30px;
  line-height: 72px;
  margin: 30px 0 14px 0;
  color: #000;
}

.cardsWrapper {
  width: 80%;
  display: flex;
  gap: 20px;
  margin-bottom: 32px;
  margin-top: 14px;
}

@media only screen and (max-width: 1500px) {
  .container {
    padding-bottom: 60px;
  }
  .cardsWrapper {
    width: 90%;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 768px) {
  .name {
    line-height: 30px;
  }
  .container {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 450px) {
  .cardsWrapper {
    flex-direction: column;
  }
  .name {
    line-height: 30px;
  }
  .container {
    padding-bottom: 40px;
  }
}
