.imgOverlay {
  filter: brightness(0.3);
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  mix-blend-mode: overlay;
  z-index: -2;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    var(--img);
}