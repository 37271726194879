.container {
  margin: 0 auto;
  width: 100%;
  height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #000000;
}

.skunkBanner {
  height: 102%;
  left: -1%;
  position: absolute;
  top: -1%;
  width: 102%;
  background-image: url("../../../assets/imgs/about-skunkdao.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

h2 {
  font-weight: 600;
  font-size: 45px;
  line-height: 72px;
  margin: 30px 0 1px 0;
  color: #fff;
  z-index: 2;
  text-align: center;
}

.description {
  font-size: 15px;
  z-index: 2;
  line-height: 20px;
  margin: 20px 0 0 0;
  color: #fff;
  width: 54%;
  text-align: center;
}
.button {
  margin-top: 30px;
}

@media only screen and (max-width: 800px) {
  .media {
    height: 800px;
  }
  .description {
    width: 85%;
  }
}

@media only screen and (max-width: 425px) {
  h2 {
    font-size: 40px;
    line-height: 50px;
    width: 85%;
  }
}
