.cardWrapper {
  box-sizing: border-box;
  width: 25%;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.imageWrapper {
  width: 100%;
  height: 193px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.link {
  text-decoration: none;
  color: inherit;
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 0px;
  margin-top: 8px;
  overflow-wrap: break-word;
}

.collectionName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}

.iconWrapper {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: 19px;

  img {
    width: 100%;
    height: 100%;
  }
}

.textContent {
  padding: 1px 16px 8px 16px;
  background-color: white;
  height: 100%;
  span {
    font-size: 12px;
  }
}

@media only screen and (min-width: 2000px) {
  .cardWrapper {
    height: 500px;
  }

  .imageWrapper {
    height: 350px;
  }
}

@media only screen and (max-width: 768px) {
  h3 {
    line-height: 14px;
  }
  .cardWrapper {
    width: 47%;
  }
}

@media only screen and (max-width: 425px) {
  .cardWrapper {
    width: 100%;
  }
  .imageWrapper {
    height: 210px;
  }
}
