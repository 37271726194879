.root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  width: calc(100% - 80px);
  height: 90vh;
  max-width: 600px;
  padding: 40px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  color: #3d3d3d;
}

.title {
  font-weight: 900;
  font-size: 28px;
  text-align: center;
  color: black;
}

.formGroup {
  margin-bottom: 10px;
  position: relative;
}

.formLabel {
  margin: 0 0 5px;
  font-size: 14px;
  color: #3d3d3d;
}

.formInput {
  width: 100%;
  outline: none;
  height: 44px;
  border-radius: 10px;
  border: 1px solid #eaeaf1;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 14px;
}

.longInput {
  resize: none;
  height: 90px;
  margin: 0;
}

h6 {
  margin: -10px 0 20px 0 !important;
}

.lengthIndicator {
  margin-top: 4px;
  margin-right: 6px;
  text-align: right;
  color: rgba(0, 0, 0, 0.6);
  font-size: 8px;

  & + .error {
    margin-top: -20px;
  }
}

.hasError {
  border: 1px solid rgb(235, 87, 87);
}

.error {
  margin: 6px 0 0 6px;
  color: rgb(235, 87, 87);
}

.footer {
  display: flex;
  justify-content: center;
}

.button {
  width: 168px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  background-color: #007bff;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.save {
  background: linear-gradient(90deg, #D905A6 0%, #FCBE0B 100%);
  color: #fff;
  margin-right: 40px;
}

.cancel {
  background: 
    linear-gradient(#fff, #fff) padding-box,
    linear-gradient(to right, #D905A6, #FCBE0B) border-box;
  border: 1px solid transparent;
}
.cancelBtn {
  background: -webkit-linear-gradient(45deg, #D905A6 0%, #FCBE0B 100%);
  background-clip: unset;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.avatarBox {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #fafafa;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.upload {
  position: absolute;
  width: 37px;
  height: 37px;
  bottom: 4px;
  right: -4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadIcon {
  width: 24px;
  height: 24px;
  color: #D905A6;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

.avatarCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
@media only screen and (max-width: 768px) {
  .paper {
    height: 75vh;
    overflow-y: scroll;
  }
  .formLabel {
    margin: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .paper {
    padding: 25px 20px 20px;
  }

  .title {
    font-size: 20px;
  }

  .footer {
    flex-direction: column;
  }

  .button {
    width: calc(100% - 48px);
    margin-left: auto;
    margin-right: auto;
  }

  .save {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .paper {
    width: 92%;
  }
}
