.container {
  width: 100%;
  background: #fff;
  padding: 16px 0;
}

.banner {
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 10px 0 0px 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 72px;
  color: #000;
}

.description {
  font-weight: 600;
  font-size: 18px;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 20px;
}

.socials {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

.socialItem {
  display: flex;
  align-items: center;
}

.image {
  margin-right: 12px;
}

.itemDescription {
  font-weight: 600;
  font-size: 14px;
  line-height: 36px;
  color: black;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .socials {
    gap: 12px;
    margin-bottom: 50px;
  }
  .title {
    margin: 28px 0 18px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
}

@media only screen and (max-width: 425px) {
  .socials {
    gap: 15px;
    margin-bottom: 14px;
  }
  .image {
    margin-right: 4px;
  }
  .description {
    margin-bottom: 10px;
  }
  .title {
    margin: 14px 0 10px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }
}