.filterHeaderRoot {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  padding-right: 20px;
  padding-top: 24px;
}

.filterHeaderLeft {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  gap: 16px;
}

.filterOption {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  font-size: 16px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  height: 44px;
  padding: 12px;
}

.filterCollectionsList {
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
}

.filterCollectionItem {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #007bff;
  background-color: rgba(0, 123, 255, 0.06);
  padding: 6px 8px;
  margin-right: 6px;
  margin-top: 10px;
}

.reset {
  background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.filterCollectionItem:hover {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
}

.filterCollectionItemLogo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.filterCollectionItemName {
  color: #007bff;
  white-space: nowrap;
  margin: 0 6px;
}

.filterCollectionRemoveItem {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.filterResultLabel {
  font-weight: 500;
  font-size: 18px;
  color: rgba(61, 61, 61, 0.6);
  white-space: nowrap;
  margin-right: 20px;
  margin-top: 10px;
}

.MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 32px;
  height: 10px !important;
}

.filterSelectGroup {
  margin-bottom: 10px;
}

.filterHeaderFormControl {
  position: relative;
  margin-right: 18px !important;
  width: 200px;
  top: unset !important;
}

.filterHeaderFormControl:last-child {
  margin-right: 0 !important;
}

.selectBox {
  padding: 12px 15px;
  box-sizing: border-box;
  border-radius: 10px !important;
  outline: none !important;
  background-color: #fff !important;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  font-size: 16px !important;
}

.selectBox::before,
.selectBox::after {
  display: none;
}

.selectInner {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.selectMenu {
  background-color: transparent !important;
}

.selectLabel {
  position: absolute !important;
  top: -9px;
  left: 12px;
  background-color: white;
  font-size: 12px;
  z-index: 2;
  padding: 0 4px;
}

.menuPropsPaper {
  box-sizing: border-box;
  padding: 25px;
  background-color: #fff;
  border-radius: 8px !important;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.15);
  transform: translate(-12px, 35px) !important;
}

.menuItemList {
  padding: 0 !important;
}

.menuItem {
  width: fit-content !important;
  font-weight: 400 !important;
  font-size: 18px;
  line-height: 22px;
  color: #8c8c8c !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
  background-color: transparent !important;
}

.menuItem:last-child {
  margin-bottom: 0 !important;
}

.menuItemSelected {
  color: #1665ee !important;
}

@media only screen and (max-width: 600px) {
  .filterHeaderRoot {
    padding-right: 0;
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 8px !important;
    margin-bottom: -15px;
  }

  .filterHeaderLeft {
    margin-top: 6px;
  }

  .filterSelectGroup {
    display: flex;
    flex-direction: column;
  }

  .filterHeaderFormControl {
    margin-right: 0 !important;
    margin-bottom: 12px !important;
  }

  .filterHeaderFormControl:last-child {
    margin-bottom: 0 !important;
  }
}
