.wrapper {
  width: 100%;
  box-sizing: border-box;
  padding-top: 155px;
  padding-bottom: 125px;
  position: relative;
}

.content {
  box-sizing: border-box;
  position: relative;
  border-radius: 10px;
  padding: 60px 0 65px 86px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  height: 980px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
  opacity: 0.2;
  filter: blur(3px);
  height: 980px;
}

.title {
  font-weight: 600;
  font-size: 70px;
  line-height: 105px;
  margin-bottom: 32px;
  max-width: 40%;
  z-index: 2;
}

.subtitle {
  font-size: 18px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 32px;
  max-width: 40%;
}

.mainButtons {
  display: flex;
  gap: 24px;
}

.exploreButton {
  display: block;
  cursor: pointer;
  text-decoration: none;
}

.card {
  position: absolute;
  right: 5%;
  top: -5%;
  width: 45%;
  max-width: 1080px;

  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 110%;
}

@media only screen and (max-width: 2400px) {
  .title {
    max-width: 45%;
  }

  .subtitle {
    max-width: 45%;
  }
}

@media only screen and (max-width: 1920px) {
  .title {
    font-size: 60px;
    line-height: 90px;
  }

  .content,
  .background {
    height: 640px;
  }

  .card {
    top: -5%;
    width: 640px;
  }
}

@media only screen and (max-width: 1600px) {
  .wrapper {
    padding-bottom: 60px;
  }
  .content {
    padding: 60px 0 65px 56px;
  }
  .subtitle {
    width: 40%;
  }
  .content,
  .background {
    height: 640px;
  }

  .card {
    top: -5%;
    width: 640px;
  }
}

@media only screen and (max-width: 1400px) {
  .title {
    width: 40%;
    font-size: 40px;
    line-height: 52px;
  }
  .subtitle {
    font-size: 12px;
    line-height: 140%;
  }
  .wrapper {
    // padding-top: 112px;
  }

  .content {
    width: 85%;
  }

  .content,
  .background {
    height: 480px;
  }

  .card {
    top: -5%;
    width: 480px;
    // display: none;
  }

  // .card {
  //   max-width: 594px;
  // }
}
@media only screen and (max-width: 1080px) {
  .title {
    width: 55%;
    max-width: 70%;
    text-align: center;
    font-size: 40px;
    line-height: 52px;
    margin-top: 20px;
  }
  .subtitle {
    font-size: 16px;
    width: 80%;
    max-width: 80%;
    text-align: center;
    line-height: 140%;
  }
  .wrapper {
    padding: 52px 0px 0px 0px;
  }

  .content {
    width: 100%;
    padding: 20px;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  .content,
  .background {
    height: 960px;
  }

  .mainButtons {
    justify-content: center;
  }

  .card {
    width: 95%;
    margin-top: 80px;
    position: relative;
    margin-left: 10%;
    height: 580px;
  }
}
@media only screen and (max-width: 425px) {
  .title {
    width: 85%;
    max-width: 100%;
    text-align: center;
    font-size: 40px;
    line-height: 45px;
  }
  .subtitle {
    font-size: 14px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    line-height: 140%;
  }
  .wrapper {
    padding: 0px;
  }

  .content {
    margin-top: 50px;
    width: 100%;
    padding: 20px;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  .content,
  .background {
    height: 960px;
  }

  .mainButtons {
    justify-content: center;
  }

  .card {
    width: 95%;
    margin-top: 80px;
    position: relative;
    margin-left: 10%;
  }
}
