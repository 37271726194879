.container {
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.loadingPanel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  max-width: 750px;
  margin-left: 100px;
  padding: 40px 0;
  box-sizing: border-box;
}

.emptyList {
  width: 100%;
  max-width: 750px;
  margin-left: 4rem;
  padding: 40px 0;
  box-sizing: border-box;
  color: rgba(0,0,0,.5);
  font-size: 1.2rem;
}

.collection {
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }
}

.collectionLogo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.collectionName {
  margin-left: 20px;
  font-size: 30px;
  color: #3d3d3d;
}

.title {
  font-size: 28px;
  font-weight: 900;
  color: #3d3d3d;
}

.inputGroup {
  margin-top: 40px;
}

.inputTitle {
  font-weight: 700;
  font-size: 22px;
  color: #3d3d3d;
  display: flex;
  align-items: center;
}

.inputTitle1 {
  font-weight: 500;
  font-size: 18px;
  color: #3d3d3d;
  display: flex;
  align-items: center;
}

.inputWrapper {
  margin-top: 16px;
}

.logoUploadBox {
  width: 160px;
  height: 160px;
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;

  &:hover {
    border-color: #888;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
  }
}

.input {
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  color: #333;
  border-radius: 10px;
  border: none;
  outline: none;
  border: 1px solid #eaeaf1;

  &:focus {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  }
}

.longInput {
  height: 120px;
  resize: vertical;
}

.categoryList {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.categoryButton {
  flex: 0 0 120px;
  height: 48px;
  padding: 8px 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 1px solid #eaeaf1;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  }
}

.menu {
  min-width: 160px;
  max-height: 350px;
  overflow-y: auto;
}

.category {
  height: 48px;
}

.categoryIcon {
  width: 20px;
  height: 20px;
}

.categoryLabel {
  margin-left: 12px;
}

.selectedCategory {
  flex: 0 0 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  border: 1px solid #eaeaf1;
  border-radius: 8px;
  user-select: none;
  white-space: nowrap;
}

.closeIcon {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.linksWrapper {
  width: 100%;
  overflow: hidden;
}

.linkItem {
  display: flex;
  flex-direction: row;
  height: 56px;
  border: 1px solid #eaeaf1;
  box-sizing: border-box;
  margin-top: 16px;
  border-radius: 5px;
  overflow: hidden;

  &:first-child {
    margin-top: 0;
  }
}

.linkIconWrapper {
  flex: 0 0 76px;
  border-right: 1px solid #eaeaf1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkIcon {
  width: 28px;
  height: auto;
  color: #999;
}

.inputPrefix {
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #999;
}

.linkInput {
  flex-grow: 1;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  padding-right: 12px;
  font-size: 16px;
  color: #333;
  background-color: transparent;
  flex-basis: 0px;

  &::placeholder {
    color: #888;
  }
}

.buttonsWrapper {
  margin-top: 40px;
  display: flex;
}

.createButton,
.rejectButton {
  margin-right: 20px;
  width: 168px;
  height: 48px;
  border-radius: 8px;
  background-color: #1969ff !important;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.rejectButton {
  background-color: #e15a5a !important;
}

.cancelButton {
  box-sizing: border-box;
  width: 168px;
  height: 48px;
  border-radius: 8px;
  background-color: transparent;
  color: #1969ff;
  border: 2px solid #1969ff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: transparent;
  }
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

@media only screen and (max-width: 768px) {
  .inner {
    margin: 0 auto;
    padding: 0 40px;
  }

  .categoryList {
    flex-direction: column;
    align-items: flex-start;
  }

  .categoryButton,
  .selectedCategory {
    flex: 0 0 48px;
  }

  .selectedCategory {
    margin-left: 0;
    margin-top: 16px;
  }

  .linkIconWrapper {
    flex: 0 0 50px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 155px;
  }
}

@media only screen and (max-width: 600px) {
  .inner {
    margin: 0;
    padding: 0 30px;
  }
}
