.wrapper {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.container {
  width: 100%;
  margin-top: 80px;
}

a {
  text-decoration: none;
  color: white;
}
