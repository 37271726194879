.container {
  display: flex;
	align-items: center;
	font-family: 'Poppins';
	gap: 6px;
	position: relative;
}

.timeBox {
	background: rgba(255, 255, 255, 0);
	width: 20px;
	border: 0.5px;
	padding: 15px;
	z-index: 9999;
	pointer-events: auto; 
	position: absolute;
	top: 0px;
    left: 0px;
	opacity: 0;
	font-weight: 600;
    font-size: 15px;
}

.timeBoxActive {
	opacity: 1;
	background: #ffffff;
}

.visibleValue {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
    font-size: 15px;
	position: relative;
	background-color: #ffffff;
}

.hideVisibleValue {
	opacity: 0;
}

.timeBoxContainer {
  	background: #ffffff;
	width: 20px;
	height: 20px;
	border-radius: 5px;
	border: 0.5px;
	margin: 10px 0;
	padding: 15px;
	position: relative;
}

.group {
	display: flex;
	flex-direction: column;
	align-items: center;
}
