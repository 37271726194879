.wrapper {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 30%;
}

.image {
  width: 100%;
  height: 200px;
  margin-bottom: 26px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  padding: 0 26px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 12px;
  text-align: center;
}

.description {
  padding: 0 26px;
  font-size: 13px;
  line-height: 125%;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 38px;
  text-align: center;
}

@media only screen and (min-width: 2000px) {
  .image {
    height: 400px;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: 100%;
  }
}
