.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #007bff !important;
}

.css-gr2aqs {
  background-color: #007bff !important;
}

body {
  margin: 0 !important;
  overflow: auto;
}
.slick-slide {
  outline: none;
  width: unset !important;
  /* width: 360px !important; */
}

body * {
  font-family: 'Poppins' !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
h2 {
  margin: 0;
  padding: 0;
}

.react-calendar {
	border: none !important;
	font-family: 'Poppins' !important;
}

.react-calendar__navigation {
	margin-bottom: 0 !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none !important;
}