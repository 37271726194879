.wrapper {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 120px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(
      90deg,
      rgba(217, 5, 166, 0.4) 0%,
      rgba(252, 190, 11, 0.4) 100%
    );
}

.banner {
  width: 75%;
  margin: 0 auto;
  background-color: white;
  display: flex;
  align-items: center;
  text-align: center;
  background: #f3f3f3;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.content {
  width: 80%;
  margin: 0 auto;
  padding: 80px;
}

.mainText {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 48px;
}

.subText {
  margin-top: 48px;
  font-weight: 400;
  font-size: 22px;
  line-height: 140.06%;
}
.linkButton {
  text-decoration: none;
}

@media only screen and (max-width: 1400px) {
  .content {
    padding: 40px 80px;
  }
  .mainText {
    font-size: 28px;
    line-height: 50px;
    margin-bottom: 25px;
  }
  .subText {
    font-size: 14px;
    margin-top: 34px;
  }
  .wrapper {
    padding-top: 72px;
    padding-bottom: 36px;
  }
}
@media only screen and (max-width: 800px) {
  .mainText {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .subText {
    font-size: 12px;
    margin-top: 26px;
  }
  .content {
    width: 100%;
    padding: 20px;
  }
  .banner {
    width: 85%;
  }
  
}
