.wrapper {
  width: 100%;
  background: #fff;
}

.container {
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {}

.content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 124px;
  padding-top: 32px;
}

.media {
  width: 40%;
  margin-top: 80px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.description {
  flex: 1;

  p {
    margin: 0;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.7);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.description_title {
  font-weight: 600;
}

.roadMap {
  width: 100%;
  background: #f3f3f3;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0;
  margin-bottom: 124px;

  .name {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #000000;
    margin: 0;
    margin: 60px 0 48px 0;
    text-align: center;
  }
}

.info {
  display: flex;
  padding-left: 180px;
  margin-bottom: 100px;
  gap: 32px;
  justify-content: flex-start;
}

.date {
  transform: translateY(-7px);
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 48px;
    display: block;
    font-size: 22px;
    line-height: 33px;
    min-width: 100px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.text {
  transform: translateY(-7px);
}

.textItem {
  margin-bottom: 48px;
  font-size: 22px;
  line-height: 33px;
  font-weight: 600;

  &:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1800px) {
  .media {
    width: 574px;
    height: 574px;
  }
}

@media only screen and (max-width: 1500px) {
  .media {
    width: 450px;
    height: 450px;
  }
  .info {
    padding-left: 80px;
  }
  .content {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 1200px) {
  .content {
    flex-direction: column-reverse;
    margin-bottom: 124px;
  }
  .media {
    width: 60%;
    margin-top: 10px;
    margin-bottom: -100px;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 800px) {
  .media {
    width: 90%;
    margin-top: -1%;
    margin-bottom: -3%;
  }
}

@media only screen and (max-width: 425px) {
  .media {
    width: 90%;
    margin-top: -90px;
    margin-bottom: -160px;
    img {
      object-fit: contain;
    }
  }
}
