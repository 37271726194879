.wrapper {
  background: linear-gradient(
    90deg,
    rgba(217, 5, 166, 0.4) 0%,
    rgba(252, 190, 11, 0.4) 100%
  );
  width: 100%;
  padding-bottom: 30px;
}

.container {
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 70px;
  margin-bottom: 87px;
}

.left {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description {
  width: 90%;
  font-size: 22px;
  line-height: 33px;
  color: rgba(0, 0, 0, 0.7);
}

.right {
  width: 455px;
  height: 455px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.title {
  width: 90%;
  margin-top: 0px;
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 80px;
  line-height: 120px;
}

@media only screen and (max-width: 1600px) {
  .container {
    margin: 0 auto;
    width: 80%;
    display: flex;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .left {
    width: 60%;
  }
  .description {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
  }
  .title {
    margin-bottom: 20px;
    font-size: 46px;
    line-height: 47px;
    width: 100%;
  }
  .right {
    width: 350px;
    height: 350px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    margin: 0 auto;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    margin-bottom: 30px;
  }
  .left, .right, .description {
    width: 90%;
  }
  .left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 42px;
    line-height: 60px;
    text-align: center;
    width: 90%;
  }
  .description {
    text-align: center;
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .title {
    line-height: 54px;
  }
}