.calendarContainer {
    width: 100%;
    max-width: 484px;
    border-radius: 30px;
    display: flex;
    position: relative;
}

.calendarWrapper {
    padding: 12px;
    width: 60%;
}

.timeWrrapper {
    background: linear-gradient(180deg, rgba(217, 5, 166, 0.2) 0%, rgba(252, 190, 11, 0.2) 100%);
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0px;
    border-radius: 0px 10px 10px 0px;
}

.closeBtn {
	position: absolute;
    top: -7px;
    right: -10px;
    height: 22px;
    width: 22px;
	border-radius: 50%;
	background-color: #fff;
	color: grey;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.invalidDate {
    font-size: 10px;
    color: #b4158d;
}

@media only screen and (max-width: 600px) {
    .calendarContainer {
        width: 100%;
        flex-direction: column;
    }
    .calendarWrapper {
        padding: 1px;
        width: 100%;
    }
    .timeWrrapper {
        width: 93%;
        border-radius: 0px;
        padding: 14px;
        flex-direction: row;
    }
}
  