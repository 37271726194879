.wrapper {
  width: 75%;
  margin: 56px auto;
}
.sectionTitle {
  font-weight: 600;
  font-size: 34px;
  line-height: 72px;
  margin-bottom: 18px;
}

.container {
  width: 100%;
  display: flex;
  height: 100%;
  gap: 48px;
}

.box {
  flex-grow: 1;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: relative;
}

.linkButton {
  text-decoration: none;
}

.expander {
  margin-top: 90%;
}

.content {
  position: absolute;
  top: 15%;
  left: 5%;
  width: 62%;
}

.boxRight {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0) 100%
    ),
    url('../../assets//imgs/disco2.jpg');
}

.boxLeft {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0) 100%
    ),
    url('../../assets//imgs/disco1.jpg');
}

.title {
  color: white;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  margin-bottom: 48px;
}

@media only screen and (max-width: 1450px) {
  .title {
    font-size: 28px;
    line-height: 50px;
  }
  .sectionTitle {
    font-size: 32px;
    margin-bottom: 8px;
  } 
  .wrapper {
    margin: 15px auto;
  } 
}

@media only screen and (max-width: 800px) {
  .wrapper {
    width: 85%;
    margin: 15px auto;
  }
  .title {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 28px;
  }
  .container {
    flex-direction: column;
  }
  .sectionTitle {
    width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 425px) {
  .title {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 28px;
  }
  .container {
    flex-direction: column;
  }
  .sectionTitle {
    font-size: 28px;
  }
}
