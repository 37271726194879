.container {
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
  margin: 0 auto;
  border-top: 1px solid #d9e1ee;
}

.sidebar {
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d9e1ee;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  transition: flex-basis ease 300ms;
}

.collapsed {
  flex: 0 0 64px;

  .sidebarHeader {
    padding: 0;
    justify-content: center;
  }

  .iconCollapse {
    transform: rotateZ(180deg);
  }

  .filterList {
    display: none;
  }
}

.detailsBody {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 0 auto;
  color: white;
  gap: 32px;
}

.sidebarHeader {
  height: 88px;
  flex: 0 0 88px;
  display: flex;
  padding: 0 32px 0 24px;
  align-items: center;
  justify-content: space-between;
}

.collectionWrap {
  height: 100vh;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-y: scroll;
}

.collectionDescription {
  font-size: 18px;
  line-height: 140%;
  letter-spacing: 1px;
  font-weight: 200;
  max-width: 80%;
}
.collectionName {
  font-weight: 600;
  font-size: 36px;
  padding-top: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.detailHead {
  display: flex;
  align-items: center;
  gap: 29px;
}

.owner {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 200;
}
.collectionCreator {
  font-weight: bold;
}

.logoContainer {
  position: relative;
  width: 135px;
  height: 135px;
}
.collectionLogo {
  border: 3px solid white;
  position: relative;
  box-sizing: border-box;
  height: 130px;
  width: 130px;
  border-radius: 100%;
  background-color: #c4c4c4;
}

.verified {
  position: absolute;
  bottom: 8px;
  right: 13px;
  height: 40px;
  width: 40px;
  background-image: url('../../assets/imgs/verified.png');
}

.socialsWrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 10%;
  top: 15%;
}

.statWrap {
  display: flex;
}

.statBox {
  // flex-grow: 1;
  min-width: 120px;
  height: 88px;
  border: 1px solid rgb(207, 207, 207);
  border-left: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px;
  background: rgba(255, 255, 255, 0.3);
}
.statBox:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.statBox:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 1px solid rgb(207, 207, 207);
}
.statValue {
  font-size: 24px;
  font-weight: 600;
}

.statName {
  font-size: 14px;
  font-weight: 200;
}

.banner {
  width: 100%;
  margin-top: 80px;
  height: 400px;
  font-weight: 400;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}

.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  mix-blend-mode: overlay;
  z-index: -2;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    var(--img);
}

.bannerPlaceholder {
  width: 100%;
  height: 400px;
  position: absolute;
  z-index: -2;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    rgb(229, 232, 235);
}

.title {
  position: relative;
  top: -16px;
}

.sidebarTitle {
  font-size: 20px;
  font-weight: 700;
  color: #3d3d3d;
}

.iconCollapse {
  cursor: pointer;
  transform: rotateZ(0deg);
  transition: transform ease 300ms;
}

.profileWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.avatar {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
}

.username {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.address {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.bio {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #555;
  text-align: center;
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 75%;
  margin: 0 auto;
  overflow-y: auto;
}

.exploreAll {
  height: fit-content;
  padding-right: 10px;
  padding-bottom: 20px;
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.filterHeader {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 75%;
  margin: 0 auto;
  padding: 10px 0;
  padding-left: 10px;
}

.chart {
  margin: 24px 0;
}
.customTooltip {
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 16px;
  padding: 12px;
}

.tooltipLabel {
  margin: 0;
}

.date {
  margin: 0 0 12px 0;
  font-weight: 700;
}

.panelSwitcher {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.panelButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 4px solid rgba(228, 149, 32, 0);
  &.active {
    border-bottom: 4px solid rgb(228, 149, 32);
  }
  &:hover {
    border-bottom: 2px solid rgb(228, 149, 32);
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loaderBig {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1600px) {
  .exploreAll {
    width: 90%;
  }
  .detailsBody {
    width: 90%;
  }
  .filterHeader {
    width: 90%;
  }
}
@media only screen and (max-width: 1024px) {
  .socialsWrap {
    top: 16%;
    right: 4% !important;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .bannerPlaceholder, .banner {
    height: 360px;
  }
  .container {
    padding-top: 133px;
  }
  
  .sidebar:not(.collapsed) {
    flex: 0 0 260px;
  }
  .detailsBody {
    height: 100%;
    margin-top: 36px;
  }
  .statContainer {
    overflow-x: scroll;
    margin-top: 41px;
    margin-bottom: -18px;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  // .statContainer {
  //   margin-top: 30px;
    
  // }
  .detailHead {
    margin-top: 22px;
  }
  .collectionLogo, .logoContainer {
    height: 80px;
    width: 80px;
  }
  .collectionName {
    font-size: 20px;
    // line-height: 22px;
  }
  .collectionDescription {
    font-size: 14px;
    text-align: left;
    max-width: 100%;
  }
  .owner {
    font-size: 14px;
  }
  .socialsWrap {
    top: 43%;
    left: 5%;
    right: inherit !important;
    align-items: flex-start;
  }
  .container {
    flex-direction: column;

    /* width */
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .sidebar {
    flex-basis: unset;
    flex-grow: 1;
  }

  .collapsed {
    flex-basis: unset;
    flex-grow: 1;

    .filterList {
      display: block;
    }
  }

  .sidebarHeader {
    display: none;
  }

  .filterHeader {
    padding-left: 24px;
    padding-right: 24px;
  }

  .body {
    margin-left: 0;
    overflow-y: visible;
  }
  .statBox {
    padding: 4px;
    height: 68px;
  }
  .statValue {
    font-size: 18px;
  }
  .statName {
    font-size: 14px;
  }
  .detailHead {
    gap: 15px;
  }
  .collectionName {
    text-align: left;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }
  .title {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    top: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .bannerPlaceholder, .banner {
    height: 320px;
  }
  .detailHead {
    margin-top: 10px;
  }
  .exploreAll {
    padding-right: 0;
    overflow-y: visible;
    flex-direction: column;
  }
  .collectionName {
    font-size: 18px;
  }
  .statContainer {
    margin-top: 30px;
    margin-bottom: -18px;
  }
  .statBox {
    padding: 4px;
    height: 58px;
  }
  .statBox:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .statBox:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .statValue {
    font-size: 16px;
  }
  .statName {
    font-size: 12px;
  }
  .detailHead {
    gap: 15px;
  }
  .collectionName {
    text-align: left;
    line-height: 20px;
    padding: 0;
    margin: 0;
  }
  .title {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    top: 0px;
  }
}
.clearFilter {
  border-radius: 12px;
  color: white;
  padding: 4px;
  font-size: 10px;
  margin-left: 6px;
  margin-top: -8px;
  background-color: red;
  cursor: pointer;
}

.resetGroup {
  background: 
        linear-gradient(#fff, #fff) padding-box,
        linear-gradient(to right, #ff44c9, #fcbe0b) border-box;
        border: 1px solid transparent;
  margin-left: 20px;
  border-radius: 10px;
  padding: 9px 10px;
  cursor: pointer;
  margin-top: 12px;
  span {
    background: linear-gradient(90deg, #d905a6 0%, #fcbe0b 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}
