.wrapper {
  box-sizing: border-box;
  padding: 44px 0;
  width: 78%;
  margin-bottom: 5%;
}

.content {
  .name {
    font-weight: 600;
    font-size: 32px;
    line-height: 72px;
    margin: 0;
    margin-bottom: 28px;
    color: black;
    text-align: left;
  }
}
.contentTab {
  display: none;
}
.contentMobile {
  display: none;
}

.cardsWrapper {
  display: flex;
  gap: 24px;
  position: relative;
  padding: 2px;
  padding-left: 25px;
}

.cardsWrapperTab {
  display: none;
  gap: 24px;
  position: relative;
  padding: 2px;
}

.arrow {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.arrowLeft {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  z-index: 3;
}

@media only screen and (max-width: 1450px) {
  .wrapper {
    padding: 12px 0px;
  }
  .content {
    .name {
      margin-bottom: 8px;
      margin-left: 1.5rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  .wrapper {
    padding: 0px 0px 15px 0px;
  }
  .content {
    display: none;
  }
  .contentTab {
    .name  {
      font-size: 34px;
      margin-bottom: 28px;
      line-height: 42px;
      display: block;
      color: #000;
    }
  }
  .contentTab {
    display: block;
  }
}
@media only screen and (max-width: 425px) {
  .wrapper {
    padding: 0px 0px 15px 0px;
  }
  .contentTab {
    display: none;
  }
  .contentMobile {
    .name  {
      font-size: 28px;
      margin-bottom: 28px;
      line-height: 36px;
      display: block;
      color: #000;
      text-align: center;
      width: 100%;
    }
  }
  .contentMobile {
    display: block;
  }
}
