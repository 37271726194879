.wrapper {
  height: 100%;
  width: 100%;
}

.cardMedia {
  width: 100%;
  overflow: hidden;
}
.imageItem {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    object-fit: cover;
  }
}

.root {
  height: 100%;
  div:first-of-type {
    height: 100%;
    div:first-of-type {
      height: 100%;
    }
  }
}

.root div.slider,
div.slider,
div.carousel,
ul,
li {
  height: 100%;
}

.cardWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  // cursor: pointer;
}

.cardInfo {
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 25px;
  padding: 23px 24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  gap: 16px;
  padding: 8px 24px;
}

.logoVerified {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.logoWrapper {
  width: 50px;
  height: 50px;
  width: 24%;
  border-radius: 100%;
  position: relative;
  margin-top: -20px;

  img {
    width: 100%;
    // height: 100%;
    border-radius: 100%;
    object-fit: contain;
    height: 12em;
    margin-top: -72px;
    padding-top: 70px;
  }
}

.cardCategory {
  font-size: 18px;
  line-height: 140%;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 4px;
  text-align: left;
}

.cardNameContainer {
  display: flex;
  align-items: center;
}

.cardName {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #000000;
  text-align: left;
}

@media only screen and (max-width: 1400px) {
  .cardInfo {
    gap: 16px;
    padding: 8px 24px;
  }
  .cardName {
    font-size: 18px;
    line-height: 20px;
  }
  .cardCategory {
    line-height: 20px;
    font-size: 12px;
    text-align: left;
  }
  .logoWrapper {
    width: 48px;
    height: 48px !important;
    width: 24%;
    margin-top: 0px;
    img {
      padding-top: 0px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .logoWrapper {
    width: 48px;
    height: 48px !important;
    width: 16%;
    img {
      width: 100%;
      height: 100%;
      margin-top: 0px;
    }
  }
}