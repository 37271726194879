.label {
  position: absolute !important;
  top: 3px;
  left: 12px;
  background-color: white;
  font-size: 12px;
  z-index: 2;
  padding: 0 4px;
}

.formGroup {
  position: relative;
}