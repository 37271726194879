.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  width: 60%;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 72px;
  margin: 96px 0 20px 0;
  text-align: left;
  color: #000;
}

.accordionsWrapper {
  width: 100%;
  margin-bottom: 128px;
}

@media only screen and (max-width: 1450px) {
  .title {
    margin: 20px 0 0px 0;
  }
  .accordionsWrapper {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 800px) {
  .content {
    width: 85%;
  }
  .title {
    margin: 16px 0 5px 0;
    text-align: left;
  }
  .accordionsWrapper {
    margin: -15px 0 78px 0;
  }
}
