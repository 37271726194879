.container {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  padding-top: 80px;
  box-sizing: border-box;
}

.body {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 100px;
}

.board {
  width: 100%;
  height: 416px;
  box-sizing: border-box;
  border-radius: 10px;
}

.title {
  font-size: 36px;
  font-weight: 600;
  line-height: 32px;
  margin: 36px 0 38px 0;
}

.content {
  display: flex;
  gap: 48px;
}

.uploadCont {
  height: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='53' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease 100ms;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.remove {
  width: 50px;
  height: 50px;
  color: #3d3d3d;
}

.browse {
  color: #1969ff;
  cursor: pointer;
}

.link {
  color: #1969ff;
  text-decoration: none;
  position: relative;
  top: 6px;
  left: 4px;
}

.columnLeft {
  max-width: 694px;
  height: 100%;
  flex: 1;
}

.columnRight {
  flex: 1;
  max-width: 694px;
  height: 100%;
  position: relative;
}

.panelInputs {
  display: flex;
}

.panelLeft,
.panelRight {
  flex: 1;
}

.panelLeft {
  margin-right: 30px;
}

.input {
  width: 100%;
  border-radius: 5px;
  background-color: #f6f6f6;
  padding: 0 22px 12px;
  margin-bottom: 20px;
}

.inputLabel {
  left: 22px;
}

.select {
  width: 100% !important;
  outline: none !important;
  height: 50px !important;
  border-radius: 10px !important;
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
  padding-right: 12px !important;
  height: 46px !important;
}

.uploadsubtitle, .uploadtitle {
  font-size: 15px;
}

.collection {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.collectionLogo {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.collectionName {
  margin-left: 12px;
  font-weight: 500;
  font-size: 18px !important;
  color: #3d3d3d;
}

.button {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 320px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  background-color: #1969ff;
  color: #fff;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fee {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #3d3d3d;
  opacity: 0.6;
  margin-top: 20px;
}

.mintStatusContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  width: 100%;
}

.tnxAnchor {
  text-decoration: unset;
  font-size: 18px;
  margin-top: 18px;
  color: #007bff;
}

.formGroup {
  margin-bottom: 48px;
  position: relative;
}

.formLabel {
  font-weight: 400;
  margin: 0 0 8px;
  font-size: 22px;
  color: #000000;
  display: flex;
  align-items: center;

  div {
    color: #e15a5a;
  }

  span {
    color: lightgrey;
    margin-left: 5px;
  }
}

.formInput {
  width: 100%;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  padding: 10px 24px;
  box-sizing: border-box;
  font-size: 18px;
}

.longInput {
  resize: none;
  height: 133px;
  margin-bottom: -8px;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

:global {
  .tui-image-editor {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.warning {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}

.warningIcon {
  width: 100px !important;
  height: 100px !important;
  color: #e15a5a;
}

.warningTitle {
  margin-top: 20px;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #3d3d3d;
}

.switchButton {
  margin-top: 40px;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #1969ff;
  color: #1969ff;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lockContent {
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 8px;
}

.formIcon {
  font-size: 1.1rem !important;
}

@media only screen and (max-width: 1600px) {
  .body {
    padding-bottom: 60px;
    width: 90%;
  }
  .formGroup {
    margin-bottom: 24px;
  }
  .formInput {
    height: 46px;
    font-size: 16px;
  }
  .formLabel {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1150px) {
  .body {
    flex-direction: column;
    padding-top: 40px;
    height: fit-content;
  }

  .board {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 920px) {
  .title {
    margin: 0px 0 38px 0;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 133px;
  }
  .uploadsubtitle, .uploadtitle {
    font-size: 13px;
  }

  .body {
    padding: 10px 20px 40px 20px;
  }
  .board {
    height: 316px;
    margin-bottom: 30px;
  }
  .title {
    font-size: 28px;
    margin: 6px 0 18px 0;
  }

  .panelInputs {
    width: 100%;
    flex-direction: column;
  }

  .panelLeft {
    margin-right: 0;
  }
  .content {
    flex-direction: column;
    grid-gap: 0px;
    gap: 0px;
  }
  .formLabel {
    display: block;
    font-size: 16px;
  }
  .lockContent {
    font-size: 15px;
    align-items: center;
  }
  .formInput {
    font-size: 14px;
  }
  .collectionName {
    font-size: 14px !important;
  }
}
