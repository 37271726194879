.toast {
  cursor: pointer;
  padding: 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
}

.toastInner {
  margin: -4px -10px;
  padding: 24px;
}

.header {
  display: flex;
  align-items: center;

  .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 14px;
  }

  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24.36px;
    color: #3d3d3d;
    white-space: pre-line;
  }
}

.body {
  margin-top: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #3d3d3d;
  white-space: pre-line;
}
