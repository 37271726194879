.container {
  position: fixed;
  top: 0;
  z-index: 59999933333;
  color: white;
  background-color: #b4158d;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.6em 12em 0.6em 0em;
}

.wrapper {
  margin-right: 4%;
  display: flex;
  gap: 3em;
  font-size: 0.8em;
}

.bridge {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.cancel {
  cursor: pointer;
  font-weight: bold;
}