.container {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  padding-top: 40px;
  box-sizing: border-box;
}

.body {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-between;
  z-index: 1;
}

@media only screen and (max-width: 1024px) {
  .body {
    padding-top: 70px;
  }

  .exploreButton {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .cardTitle {
    font-size: 18px;
  }

  .cardDesc {
    margin-top: 10px;
    font-size: 12px;
  }

  .cardIconWrapper {
    width: 94px;
    height: 94px;

    img {
      width: 70px;
      height: 70px;
    }
  }

  .cardIconWrapper2 {
    width: 108px;
    height: 108px;

    img {
      width: 56px;
      height: 56px;
    }
  }

  .cardLabelWrapper {
    margin-top: 56px;
    height: 54px;
  }

  .cardLabel {
    font-size: 18px;
  }

  .browseBtn {
    width: 40px;
    height: 40px;
  }

  .footer {
    height: 128px;
    padding: 0 32px;
  }
}

@media only screen and (max-width: 900px) {
  .aboutCards {
    flex-wrap: wrap;
    padding-bottom: 58px;
  }

  .aboutCard {
    flex: 0 0 calc(50% - 11px);
    margin-bottom: 22px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  .categoryCard {
    flex: 0 0 calc(50% - 11px);
    margin-right: 22px;

    &:nth-child(3n) {
      margin-right: 22px;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .body {
    padding-top: 0px;
  }
  .container {
    padding-top: 62px;
  }

  .main {
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }

  .card {
    width: 100%;
    max-width: 400px;
  }

  .mainLeft {
    margin-top: 32px;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 48px;
    line-height: 58px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  .exploreButton {
    width: 168px;
    height: 48px;
    font-size: 16px;
  }

  .aboutTitle {
    margin-top: 72px;
    font-size: 48px;
    line-height: 58px;
  }

  .aboutCards {
    margin-top: 52px;
    flex-direction: column;
  }

  .aboutCard {
    flex: 0 0 100%;
    margin-right: 0;
  }

  .categoryCard {
    flex-basis: 100%;
    margin-right: 0 !important;
  }

  .footer {
    margin-top: 0;
  }
}
